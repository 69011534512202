export const googlePay_merchantInfo = {
  worldpay_gateway: process.env.REACT_APP_WORLDPAY_GATEWAY,
  emerchantpay_gateway: process.env.REACT_APP_EMERCHANTPAY_GATEWAY,

  gatewayWorldpayMerchantId2D:
    process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_2D,
  gatewayWorldpayMerchantId3D:
    process.env.REACT_APP_GATEWAY_WORLDPAY_MERCHANT_ID_3D,

  gatewayEmerchantpayMerchantId:
    process.env.REACT_APP_GATEWAY_EMERCHANTPAY_MERCHANT_ID,

  merchantIdWorldpay3D: process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_3D,
  merchantIdWorldpay2D: process.env.REACT_APP_GOOGLE_MERCHANT_ID_WORLDPAY_2D,
  merchantIdEmerchantpay: process.env.REACT_APP_GOOGLE_MERCHANT_ID_EMERCHANTPAY,

  merchantNameWorldpay3D:
    process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_3D,
  merchantNameWorldpay2D:
    process.env.REACT_APP_GOOGLE_MERCHANT_NAME_WORLDPAY_2D,
  merchantNameEmerchantpay:
    process.env.REACT_APP_GOOGLE_MERCHANT_NAME_EMERCHANTPAY,

  environment: process.env.REACT_APP_GOOGLE_ENVIRONMENT,
};

export const applePay_info = {
  merchantIdentifier2d: process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_2D,
  merchantIdentifier3d: process.env.REACT_APP_APPLE_MERCHANT_IDENTIFIER_3D,
};
